
import JigTextField from "@/components/input/JigTextField.vue"
import TenantSwitchField from '@/components/input/TenantSwitchField.vue'
import DataTable from '@/components/material/DataTable.vue'
import GenericError from '@/components/snackbars/GenericError.vue'
import { DataTableHeadings } from '@/modules/library/types'
import {
  GetTenantsByUserRequest,
  Tenant,
  TenantCreateRequest,
  TenantLogoImageResponse,
  TenantsPaginationPayload,
} from '@/store/modules/app/types'
import { Namespace } from '@/store/types'
import { AppHelper } from '@/utils/app-helper'
import { ValidationRules } from '@/utils/input-validation'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { Component, Vue } from 'vue-property-decorator'
import { Action, Mutation, State } from 'vuex-class'

@Component({
  components: {
    'generic-error': GenericError,
    'jig-text-field': JigTextField,
    'tenant-switch-field': TenantSwitchField,
    'data-table': DataTable,
  },
})

export default class TenantsVue extends Vue {
  @State('loadingModal', { namespace: Namespace.Utils })
  public loadingModal!: boolean
  @State('loading', { namespace: Namespace.Utils })
  public loading!: boolean
  @State('apiError', { namespace: Namespace.Utils })
  public apiError!: string
  @State('tenants', { namespace: Namespace.App })
  public tenants?: Tenant[]
  @State('totalTenantsQty', { namespace: Namespace.App })
  public totalTenantsQty!: number
  @State('totalTenantsPages', { namespace: Namespace.App })
  public totalTenantsPages!: number
  @State('tenantsItemsPerPage', { namespace: Namespace.Utils })
  public tenantsItemsPerPage!: number
  @State('allTenantsCurrentPage', { namespace: Namespace.Utils })
  public allTenantsCurrentPage!: number
  @State('allTenantsSearchCriteria', { namespace: Namespace.Utils })
  public allTenantsSearchCriteria!: string
  @State('myTenantIndex', { namespace: Namespace.App })
  public myTenantIndex!: number

  @Mutation('updateAllTenantsCurrentPage', { namespace: Namespace.Utils })
  public updateAllTenantsCurrentPage!: any
  @Mutation('updateAllTenantsSearchCriteria', { namespace: Namespace.Utils })
  public updateAllTenantsSearchCriteria!: any

  @Action('loadMyTenant', { namespace: Namespace.App })
  public loadMyTenant: any
  @Action('loadMyTenants', { namespace: Namespace.App })
  public loadMyTenants: any
  @Action('searchPaginatedTenants', { namespace: Namespace.App })
  public searchPaginatedTenants: any
  @Action('createTenant', { namespace: Namespace.App })
  public createTenant: any
  @Action('updateTenant', { namespace: Namespace.App })
  public updateTenant: any
  @Action('setMyTenant', { namespace: Namespace.App }) public setMyTenant: any
  @Action('updateMyTenantsWithNewTenant', { namespace: Namespace.App })
  public updateMyTenantsWithNewTenant: any
  @Action('setTenantLogo', { namespace: Namespace.App })
  public setTenantLogo: any

  public $refs!: Vue['$refs'] & {
    genericError: GenericError,
    tenantsListing: any,
  }

  private superUser: boolean = false
  private dialogVisible: boolean = false
  private nameRules !: any
  private emailValidation !: any
  private dialogValid: boolean = false
  private isSearchingTenants: boolean = false
  private editedTenant!: Tenant
  private searchCriteria: string = ''
  private currentPage: number = 1
  private activeTenantLogos: number[] = []
  private tenantsListHeadings: DataTableHeadings = {
    id: {
      copy: 'ID',
      sort: false,
    },
    logo: {
      copy: 'Logo',
      sort: false,
    },
    name: {
      copy: 'Team name',
      sort: false,
    },
    count: {
      copy: 'User count',
      sort: false,
    },
    tier: {
      copy: 'Subscription tier',
      sort: false,
    },
    pricing: {
      copy: 'Pricing set',
      sort: false,
    },
    active: {
      copy: 'Active',
      sort: false,
    },
    'action': {
      copy: 'Action',
      sort: false,
    },
  }

  constructor() {
    super()
    this.nameRules = ValidationRules.RequiredName
    this.emailValidation = ValidationRules.OptionalEmail[0]
    this.editedTenant = {
      Active: false,
      ID: -1,
      Name: 'Team Name',
      UserCount: 0,
      CreatedAt: new Date(),
      ColorHex1: TenantHelpers.TenantDefaultColor1Hex,
      ColorHex2: TenantHelpers.TenantDefaultColor2Hex,
      Logourl: '',
      JigFinishedCustomLink: '',
      LogoUpdated: false,
    } as Tenant
    const tenantTokens = this.$auth0.tenants
    this.superUser = TenantHelpers.IsSuperUser(tenantTokens)
  }

  protected async mounted() {
    if (this.$route.query && this.$route.query.page && this.$route.query.page !== '1') {
      this.updateAllTenantsCurrentPage(parseInt(this.$route.query.page as string, 10))
      this.currentPage = parseInt(this.$route.query.page as string, 10)
    } else if (this.allTenantsCurrentPage > 1) {
      this.currentPage = this.allTenantsCurrentPage
    }

    if (this.$route.query && typeof this.$route.query.search === 'string') {
      this.searchCriteria = this.$route.query.search
      this.updateAllTenantsSearchCriteria(this.allTenantsSearchCriteria)
    } else if (this.allTenantsSearchCriteria !== '') {
      this.searchCriteria = this.allTenantsSearchCriteria
    }

    if (this.myTenantIndex === TenantHelpers.InvalidTenantIndex || !this.tenants || !this.tenants.length) {
      const reqPayload = {
        UrlParams: {
          all: 'false',
        },
        ModalLoad: true,
      } as GetTenantsByUserRequest
      await this.loadMyTenants(reqPayload)
    }

    await this.updatePaginatedTenants()
    this.loadTenantLogos()
  }

  private updateTenantThumbnail(payload: TenantsPaginationPayload) {
    this.currentPage = payload.page
    // this.searchCriteria = payload.searchCriteria || ''

    this.activeTenantLogos = (this.tenants as Tenant[]).filter((tenant: Tenant) => tenant.Logourl && tenant.LogoImage === TenantHelpers.defaultLogoImagePath || tenant.LogoUpdated).map((tenant: Tenant) => tenant.ID)

    TenantHelpers.LoadLogos(
      (this.tenants as Tenant[]),
      (response: TenantLogoImageResponse) => {
        this.setTenantLogo(response)

        this.activeTenantLogos.splice(this.activeTenantLogos.indexOf(response.ID), 1)
      }
    )
  }

  // openNewTenantDialog - Open the new category dialog
  private openNewTenantDialog() {
    this.editedTenant.Active = true
    this.editedTenant.Name = 'Team ' + this.getDatetimeSuffix()

    this.dialogVisible = true
  }

  private getDatetimeSuffix() {
    const now: Date = new Date()
    const formatMonth: string = String(now.getMonth()).padStart(2, '0')
    const formatDate: string = String(now.getDate()).padStart(2, '0')

    return `${now.getFullYear()}${formatMonth}${formatDate}`
  }

  private cancelDialog() {
    this.dialogVisible = false
  }

  private async confirmDialog() {
    const createdTenant = await this.createTenant({
      Name: this.editedTenant.Name,
      ColorHex1: TenantHelpers.TenantDefaultColor1,
      ColorHex2: TenantHelpers.TenantDefaultColor2,
    } as TenantCreateRequest)

    if (createdTenant) {
      this.dialogVisible = false
      this.editedTenant.ID = createdTenant.ID
      await this.loadMyTenant({ TenantId: createdTenant.ID, clearCache: true })
      await this.switchToTenantById(createdTenant.ID)
    }
  }

  private get dialogActiveLabel(): string {
    return this.editedTenant.Active ? 'Active' : 'Inactive'
  }

  private isJigSpaceStaff(tenant: Tenant): boolean {
    return tenant.ID === TenantHelpers.GetJigSpaceStaffTenantID()
  }

  private async switchToTenant(tenant: Tenant) {
    if (tenant === null) {
      return
    }

    this.updateMyTenantsWithNewTenant(tenant)
    await this.switchToTenantById(tenant.ID)
  }

  public async onSwitchSuccessful(tenantId: number) {
    this.$router.push({ path: '/tenant' })
  }

  private async switchToTenantById(tenantId: number) {
    await this.setMyTenant({
      TenantId: tenantId,
    })
    this.$router.push({ path: '/tenant' })
  }

  private async loadTenantLogos() {
    if (this.tenants === undefined) {
      return
    }
    this.updateTenantThumbnail({
      page: this.currentPage,
      itemsPerPage: this.tenantsItemsPerPage,
    })
  }

  private get getQueryParams() {
    const routerOption: any = {
      page: `${this.currentPage}`
    }

    if (this.searchCriteria !== '') {
      routerOption.search = this.searchCriteria
    }

    return routerOption
  }

  private async onItemsPerPageChange(newItemsPerPage: number) {
    // This is a 2 way binding
    this.$store.state.utils.tenantsItemsPerPage = newItemsPerPage

    if (this.$route.query.page !== '1' && this.currentPage * newItemsPerPage > this.totalTenantsQty) {
      this.currentPage = 1
      this.updateAllTenantsCurrentPage(this.currentPage)
    }

    if (!AppHelper.isDeepEqual(this.$route.query, this.getQueryParams)) {
      this.$router.replace({ query: this.getQueryParams })
    }

    await this.updatePaginatedTenants()
    this.loadTenantLogos()
  }

  private async searchTenants() {
    this.updateAllTenantsSearchCriteria(this.searchCriteria)

    if (this.$route.query.page !== '1') {
      this.currentPage = 1
      this.updateAllTenantsCurrentPage(this.currentPage)
    }

    await this.updatePaginatedTenants()
    this.loadTenantLogos()
  }

  private async updatePaginatedTenants() {
    this.isSearchingTenants = true
    const searchParams: any = {
      page: this.currentPage,
      limit: this.tenantsItemsPerPage,
    }

    // TODO: Implement sort when it's available via endpoint.

    const isSearchingViaEmail = this.emailValidation(this.searchCriteria)
    if (isSearchingViaEmail === true) {
      searchParams.email = this.searchCriteria
    } else {
      searchParams.name = this.searchCriteria
    }

    if (!AppHelper.isDeepEqual(this.$route.query, this.getQueryParams)) {
      this.$router.replace({ query: this.getQueryParams })
    }

    await this.searchPaginatedTenants(searchParams)
    this.isSearchingTenants = false
    this.updateAllTenantsCurrentPage(this.currentPage)
  }

  private async onPageChange(newCurrentPage: number) {
    this.currentPage = newCurrentPage
    await this.updatePaginatedTenants()
    this.loadTenantLogos()
  }
}
